import React from 'react';
import { graphql } from 'gatsby';

import { Layout, Page as P, Seo } from '@app/components';

import beauty from '@app/assets/images/surgery-prices/beauty.png';
import rhinoplasty from '@app/assets/images/surgery-prices/Rhinoplasty.png';
import breastAugmentation from '@app/assets/images/surgery-prices/Breast-Augmentation.png';
import jowlLiposuction from '@app/assets/images/surgery-prices/Jowl-Liposuction.png';
import profound from '@app/assets/images/surgery-prices/Profound.png';
import eyeLid from '@app/assets/images/surgery-prices/Eye-Lid.png';
import transplantation from '@app/assets/images/surgery-prices/Transplantation.png';
import vaserLiposuction from '@app/assets/images/surgery-prices/Vaser-Liposuction.png';
import abdominoplasty from '@app/assets/images/surgery-prices/Abdominoplasty.png';
import genitalSurgery from '@app/assets/images/surgery-prices/Genital-Surgery.png';
import skinSurgery from '@app/assets/images/surgery-prices/Skin-Surgery.png';
import bodyContouring from '@app/assets/images/surgery-prices/Body-Contouring.png';
import buttLift from '@app/assets/images/surgery-prices/Butt-Lift.png';

const Page: React.FC = () => {
  return (
    <Layout>
      <Seo title="Surgery Prices - Clinic SBB International" />
      <P.Head
        title="Surgery Prices"
        date="January 22, 2022"
        breadcrumb={[
          { path: '/', name: 'Home' },
          {
            path: '/surgery-prices',
            name: 'Surgery Prices',
          },
        ]}
      />

      <div className="py-16">
        <div className="container mx-auto px-4">
          <div className="flex justify-center">
            <div className="w-full lg:w-3/5">
              <h1 className="text-2xl font-bold">Surgery Prices</h1>

              <div className="grid grid-cols-2 lg:grid-cols-6 gap-y-16 gap-x-4 mt-24">
                <a href="/en/aesthetic-face-surgery" className="inline-flex flex-col space-y-8">
                  <img src={beauty} alt="Aesthetic Face Surgery" />
                  <p className="font-bold text-center hover:text-primary">Aesthetic Face Surgery</p>
                </a>
                <a href="/en/aesthetic-nose-surgery" className="inline-flex flex-col space-y-8">
                  <img src={rhinoplasty} alt="Aesthetic Nose Surgery" />
                  <p className="font-bold text-center hover:text-primary">Aesthetic Nose Surgery</p>
                </a>
                <a href="/en/aesthetic-breast-surgery" className="inline-flex flex-col space-y-8">
                  <img src={breastAugmentation} alt="Aesthetic Breast Surgery" />
                  <p className="font-bold text-center hover:text-primary">
                    Aesthetic Breast Surgery
                  </p>
                </a>
                <a href="/en/aesthetic-face-surgery" className="inline-flex flex-col space-y-8">
                  <img src={jowlLiposuction} alt="Aesthetic Face Surgery" />
                  <p className="font-bold text-center hover:text-primary">Aesthetic Face Surgery</p>
                </a>
                <a href="/en/profound-before-after" className="inline-flex flex-col space-y-8">
                  <img src={profound} alt="Profound" />
                  <p className="font-bold text-center hover:text-primary">Profound</p>
                </a>
                <a
                  href="/en/periorbital-aesthetic-surgery"
                  className="inline-flex flex-col space-y-8"
                >
                  <img src={eyeLid} alt="Periorbital Aesthetic Surgery" />
                  <p className="font-bold text-center hover:text-primary">
                    Periorbital Aesthetic Surgery
                  </p>
                </a>
                <a href="/en/transplantation" className="inline-flex flex-col space-y-8">
                  <img src={transplantation} alt="Transplantation" />
                  <p className="font-bold text-center hover:text-primary">Transplantation</p>
                </a>
                <a href="/en/vaser" className="inline-flex flex-col space-y-8">
                  <img src={vaserLiposuction} alt="Vaser" />
                  <p className="font-bold text-center hover:text-primary">Vaser</p>
                </a>
                <a href="/en/tummy-tuck-surgery" className="inline-flex flex-col space-y-8">
                  <img src={abdominoplasty} alt="Tummy Tuck Surgery" />
                  <p className="font-bold text-center hover:text-primary">Tummy Tuck Surgery</p>
                </a>
                <a href="/en/aesthetic-genital-surgery" className="inline-flex flex-col space-y-8">
                  <img src={genitalSurgery} alt="Aesthetic Genital Surgery" />
                  <p className="font-bold text-center hover:text-primary">
                    Aesthetic Genital Surgery
                  </p>
                </a>
                <a href="/en/skin-surgery" className="inline-flex flex-col space-y-8">
                  <img src={skinSurgery} alt="Skin Surgery" />
                  <p className="font-bold text-center hover:text-primary">Skin Surgery</p>
                </a>
                <a href="/en/body-contouring-surgery" className="inline-flex flex-col space-y-8">
                  <img src={bodyContouring} alt="Body Contouring Surgery" />
                  <p className="font-bold text-center hover:text-primary">
                    Body Contouring Surgery
                  </p>
                </a>
                <a href="/en/brazilian-lift" className="inline-flex flex-col space-y-8">
                  <img src={buttLift} alt="brazilian-lift" />
                  <p className="font-bold text-center hover:text-primary">brazilian-lift</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
